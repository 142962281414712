.dataTables_paginate.paging_simple_numbers.category {
    float: right;
}

.dataTables_wrapper .dataTables_paginate {
    padding: 10px 0;
}

.dataTables_paginate.paging_simple_numbers.category .previous a {
    /* color: #40189D; */
    color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category .next a {
    /* color: #40189D; */
    color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category li:nth-child(1) {
    background: transparent !important;
    color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li{
    border: 0 !important;
    padding: 7px 16px;
    border: 0px solid #40189D !important;
    /* background: rgba(64, 24, 157, 0.3) !important; */
    border-radius: 1rem;
    color: var(--bs-pagination-color) !important;
    font-size: 16px;
    margin: 0;
    display: inline-block;
  }


  .dataTables_paginate.paging_simple_numbers.category li:hover{
       background: rgba(18, 18, 209, 0.1) !important;
  }
  /* .dataTables_paginate.paging_simple_numbers.category li:nth-child(1){
  
    background: transparent !important;
    
   
  } */
  .dataTables_paginate.paging_simple_numbers.category li:last-child{
   
    background: transparent !important;
    color: var(--bs-pagination-color) !important;
  }
  .fw-semibold .text-gray-600 tr td {
    color: var(--bs-pagination-color) !important;
  }
  
  .dataTables_paginate.paging_simple_numbers.category li.selected{
    color: #fff !important;
    background: #009ef7 !important;
  }


  .productDetailsImg{
    width:200px;
    height: 200px;
  }

  .productDetailsImg img{
    max-width:100%;
    max-height: 100%;
  }

  .proMultiImg img{
    border:1px solid #000000;
    width: 90px;
    height: 90px;
    margin:8px
  }

  .image-item {
    display: flex;
    margin: 10px 0;
  }
  .image-item__btn-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .chatBoxHeight{
    height: 300px !important;
  }
  .card-body.pt-0 {
    overflow: auto;
}

.proMultiImgMAin{
  position: relative;
  width: 106px;
}
.editimagicon{
  position: absolute;
  top: 0px;
  background: #fff;
  padding: 5px;
  border-radius: 9px;
  right: 0px;
  cursor: pointer;
}